export default class {
  static spec(card) {
    const list = card.shapeUp.list
    // The cache might not be ready
    if (list) {
      return {
        "view": "chip",
        "styleClasses": $tpu.styles.chipClasses(),
        "text": list.name.truncate(20),
        "backgroundColor": $tpu.styles.listColor(list),
        "onClick": this.listSelectAction(card)
      }
    }
    return {
      "view": "spacer",
    }
  }

  static listSelectAction(card) {
    const lists = $tpu.r.lists.findAll()
    return {
      "action": "sheets/select",
      "message": "Select a list to move this card",
      "buttons": lists.map((list) => {
        return {
          "text": list.name,
          "onClick": {
            "action": "commands/custom",
            "name": "cards/update",
            "properties": {
              // "onlyRefreshBoard": true,
              "cardId": card.id,
              "formData": {
                "idList": list.id
              }
            }
          },
          "type": "button"
        }
      })
    }
  }
}
